<template>
  <div>
    <NavComponent class="nav"/>
    <section>
      <GreetComponent data-aos="fade-up" data-aos-anchor-placement="top-center" class="greet"/>
    </section>
    <section id="machine">
      <MachineLearningComponent data-aos="fade-up" data-aos-anchor-placement="top-center"/>
    </section>
    <section id="blockchain">
      <BlockchainComponent data-aos="fade-up" data-aos-anchor-placement="top-center"/>
    </section>
    <section id="devops">
      <DevOpsComponent data-aos="fade-up" data-aos-anchor-placement="top-center"/>
    </section>
    <section id="hobbies">

    </section>
  </div>
</template>

<script>
import BlockchainComponent from "@/components/BlockchainComponent";
import DevOpsComponent from "@/components/DevOpsComponent"
import GreetComponent from "@/components/GreetComponent"
import MachineLearningComponent from "@/components/MachineLearningComponent";
import NavComponent from "@/components/NavComponent"

export default {
  name: "Home",
  components: {
    BlockchainComponent,
    DevOpsComponent,
    GreetComponent,
    MachineLearningComponent,
    NavComponent
  },
}
</script>

<style lang="scss" scoped>
@import "../css/bulma.scss";

h1 {
  color: $primary;
}

section {
  margin-bottom: 18em;
}

.greet {
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 3em;
}

</style>