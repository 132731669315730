<template>
  <div>
    <div class="container">
      <div class="intro">
        <h1>DevOps Engineering</h1>
      </div>
      <div class="content">
        <p>
          Proven proficiency in the development, deployment, and maintenance of
          fault-tolerant and scalable services in the cloud (Azure) or self-hosted.
          Deployment architectures include: Data lake, Lambda, Delta, K.
        </p>
        <p>
          Experienced in database replication techniques to leverage client
          requirements from the CAP theorem perspective. Knowledge of leveraging
          SQL/NoSQL solutions.
        </p>
        <div class="columns">
          <div class="column">
            <p>Technologies I use everyday:</p>
            <ul>
              <li>Docker</li>
              <li>Kubernetes</li>
              <li>Kafka</li>
              <li>Go</li>
              <li>Git</li>
              <li>Cassandra</li>
              <li>MongoDB</li>
              <li>Vue</li>
              <li>NGINX / Traefik</li>
              <li>Socket.io</li>
            </ul>
            <p>Technologies I use sometimes:</p>
            <ul>
              <li>Spark (distributed MapReduce ML Tasks)</li>
              <li>PostgreSQL</li>
              <li>RabbitMQ</li>
              <li>MQTT</li>
              <li>Modbus</li>
            </ul>
          </div>
          <div class="column">
              <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }]
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../css/bulma.scss";

.intro {
  color: $primary;
  font-family: "JetBrains Mono", monospace;
  text-align: left;
  margin-bottom: 1em;
}

.button {
  font-family: "JetBrains Mono", monospace;
}

.content {
  font-family: "JetBrains Mono", monospace;
  text-align: justify;
  margin-left: 7em;
  margin-right: 7em;
}

.highlighted {
  color: $primary;
}

img {
  width: 100%;
  height: auto;
}
</style>
