import Vuex from 'vuex'
import Vue from 'vue'
import projectsModule from "@/store/modules/projectsModule"

// Load vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
    modules: {
        projects: projectsModule
    }
})