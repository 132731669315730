<template>
  <div>
    <div class="container">
      <div class="intro">
        <h1>Blockchain Cryptocurrency and Supply-Chains</h1>
      </div>
      <div class="content">
        <p>After being introduced to distributed systems computing and decentralized ledgers in 2021 I've been working
          on creating my own cryptocurrency.
          The platform is called <span class="highlighted"> Merkel Coin</span> and it serves two purposes: <span
              class="highlighted">Digital assets transactions</span> and
          <span class="highlighted">scientific computing as proof-of-work.</span></p>
        <div class="columns">
          <div class="column">
            <code>
              BLOCKS
              { "index": 0, "nonce": 477733, "difficulty": 18, "version": "MC4wLjE=", "timestamp":
              "2021-05-24T12:36:22.899227838+02:00", "transaction": [ { "ID":
              "8MkJO3brwqemkDt8bo/GRF0EXnEP7By08mKTK24iNuU=", "TxIN": { "tx_in_contents": [ { "tx_out_id":
              "YzJsbzM5aGY4bnVxdDlobjlrcDA=", "tx_out_index": 0 } ] }, "TxOUT": { "tx_out_contents": [ { "address": {
              "N": null, "E": 65537 }, "amount": 9, "spendable": true } ] } } ], "hash": "TWVya2VsIGJsb2NrLg==",
              "previous": "VGhlIEdlbmVzaXMu", "valid": "AAA++WrbD16euhU+sTFGjxX3w3k2d++VVVJGQwxg988=" }
              { "index": 1, "nonce": 0, "difficulty": 18, "version": "MC4wLjE=", "timestamp":
              "2021-05-24T12:36:48.667441862+02:00", "transaction": [ { "ID":
              "n+Oj6CCqTmsZuzC4B1X4T8x/uACIxf3prOFzUa5TtA4=", "TxIN": { "tx_in_contents": [ { "tx_out_id":
              "YzJsbzNnMWY4bnVxdDlobjlrcGc=", "tx_out_index": 0 } ] }, "TxOUT": { "tx_out_contents": [ { "address": {
              "N": null, "E": 65537 }, "amount": 9, "spendable": true } ] } } ], "hash":
              "VvEzU4nvrFCVKbGMbjxgoKa3MnooNWFU6vVIDf6SfEs=", "previous": "TWVya2VsIGJsb2NrLg==", "valid": null }
              ...
            </code>
            <br>
            <code>
              TRANSACTIONS
              [ { "ID": "8MkJO3brwqemkDt8bo/GRF0EXnEP7By08mKTK24iNuU=", "TxIN": { "tx_in_contents": [ { "tx_out_id":
              "YzJsbzM5aGY4bnVxdDlobjlrcDA=", "tx_out_index": 0 } ] }, "TxOUT": { "tx_out_contents": [ { "address": {
              "N": null, "E": 65537 }, "amount": 9, "spendable": true } ] } } ]
              [ { "ID": "n+Oj6CCqTmsZuzC4B1X4T8x/uACIxf3prOFzUa5TtA4=", "TxIN": { "tx_in_contents": [ { "tx_out_id":
              "YzJsbzNnMWY4bnVxdDlobjlrcGc=", "tx_out_index": 0 } ] }, "TxOUT": { "tx_out_contents": [ { "address": {
              "N": null, "E": 65537 }, "amount": 9, "spendable": true } ] } } ]
              ...
            </code>
          </div>
          <div class="column">
            <p>At its heart, Merkel Coin was inspired by <a href="https://www.cryptokitties.co/">crypto kitties</a> and
              thought of
              as a platform to trade digital assets. The nature of this digital asset can be anything, as long as
              transactions upon the
              Merkel network operate using a mineable token called a <span class="highlighted">merkel</span>.</p>
            <p>As a scientist, I see the value of people contributing computational power towards fellow scientists. As
              such,
              <span class="highlighted">mining merkels </span>constitutes computing somebody's job that they
              transactioned on the network. Once the job is completed,
              a coinbase transaction occurs to the <span class="highlighted">miner</span> of the network, rewarding them
              for their
              contribution.</p>
            <p>Currently, the inner workings of the coin resemble that of Bitcoin, however the future of the project
              will accommodate for smart contracts involving user-submitted
              compute jobs.</p>
            <p>The whole infrastructure is written from scratch in <span class="golang">Go</span>.
              The <span class="highlighted">infrastructure</span> consists of a decentralized system:</p>
            <ul>
              <li>Authentication API for wallets</li>
              <li>Compute nodes holding the ledger</li>
              <li>Client-side application for node registration and mining capabilities</li>
              <li>AES encryption for signing transactions</li>
            </ul>
            <b-button class="button" label="Visit Merkel Coin"></b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockchainComponent"
}
</script>

<style lang="scss" scoped>
@import "../css/bulma.scss";

a {
  color: $primary;
}

.golang {
  color: #4099FF;
}

.intro {
  color: $primary;
  font-family: "JetBrains Mono", monospace;
  text-align: left;
  margin-bottom: 1em;
}

.button {
  font-family: "JetBrains Mono", monospace;
}

.content {
  font-family: "JetBrains Mono", monospace;
  text-align: justify;
  margin-left: 7em;
  margin-right: 7em;
}

.highlighted {
  color: $primary;
}

img {
  width: 100%;
  height: auto;
}

</style>