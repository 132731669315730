<template>
  <div>
    <p class="intro">Hello, my name is</p>
    <div class="greeting">
      <h1 class="name">Mihai Popescu.</h1>
      <h3 class="description">I'm a learner.</h3>
      <p class="subdescription">I'm a Deep Learning Engineer based in <span class="highlighted">Groningen</span>,
        The Netherlands working <a href="https://umcgresearch.org/" class="highlighted">@UMCG.</a>
        <br>
        I also work part time in <span class="highlighted"> smart home automation </span> and <span class="highlighted">
        cloud infrastructure </span> as a DevOps Engineer <a href="https://jeemz.com/">@Jeemz.</a>
        <br>
        Deeply interested in <span class="highlighted">blockchain technology</span> and am such developing my own cryptocurrency from scratch named
        <a href="#">Merkel Coin</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GreetComponent"
}
</script>

<style lang="scss" scoped>
@import "../css/bulma.scss";
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&family=Noto+Serif:wght@700&display=swap');

.intro {
  color: $primary;
  font-family: "JetBrains Mono", monospace;
  text-align: left;
}

.greeting {
  font-family: "Noto Serif CJK JP", serif;
  text-align: left;
}

.name {
  color: $secondary;
  font-weight:bold;
  font-size: 3em;
}

.description {
  color: $secondary;
  font-weight:bold;
  font-size: 2.5em;
}

.highlighted {
  color: $primary;
}

.subdescription {
  color: $secondary;
  margin-top: 1em;
  font-family: "JetBrains Mono", monospace;
  align-content: end;
}



</style>