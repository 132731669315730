<template>
  <div>
    <b-navbar active type="is-secondary">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <!--            <img-->
          <!--                src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"-->
          <!--                alt="Lightweight UI components for Vue.js based on Bulma"-->
          <!--            >-->
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item class="link-button" href="#machine">
          <p class="count">01.</p>
          <p class="text"> Machine Learning</p>
        </b-navbar-item>
        <b-navbar-item class="link-button" href="#blockchain">
          <p class="count">02. </p>
          <p class="text"> Blockchain</p>
        </b-navbar-item>
        <b-navbar-item class="link-button" href="#devops">
          <p class="count">03. </p>
          <p class="text"> DevOps</p>
        </b-navbar-item>
        <!--        <b-navbar-item class="link-button" href="#hobbies">-->
        <!--          <p class="count">04. </p>-->
        <!--          <p class="text"> Hobbies</p>-->
        <!--        </b-navbar-item>-->
        <b-navbar-item class="link-button" href="https://www.linkedin.com/in/mihai-popescu-50744259">
          <p class="count">04. </p>
          <p class="text"> Contact</p>
        </b-navbar-item>
      </template>
      <template #end>
        <a class="brand" href="https://www.linkedin.com/in/mihai-popescu-50744259">
          <i class="fab fa-linkedin"></i>
        </a>
        <a class="brand" href="https://www.github.com/ubervelocity">
          <i class="fab fa-github"></i>
        </a>
        <a href="Resume-Mihai-Popescu.pdf" class="button">
          <p class="resume-text">Resume</p>
        </a>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavComponent",
}
</script>

<style lang="scss" scoped>
@import "../css/bulma";

.count {
  color: $primary;
  font-family: "JetBrains Mono", monospace;
}

.text {
  color: $secondary;
  font-family: "JetBrains Mono", monospace;
}

.text:hover {
  background-color: #FC6471;
  transition: 0.3s;
}

.button {
  font-family: "JetBrains Mono", monospace;
  color: $primary;
  background-color: white;
  border-color: $primary;
  margin-right: 5em;
}

.brand {
  color: $secondary;
}

.fab {
  font-size: 2.5em;
  margin-right: 0.5em;
}

.button:hover {
  border-color: $primary;
}

</style>
