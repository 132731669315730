<template>
  <div>
    <div class="container">
      <div class="intro">
        <h1>Medical Artificial Intelligence</h1>
      </div>
      <div class="content">
        <p>I've been involved in many medical projects concerning the application of <span class="highlighted"> machine learning</span>.
          Currently practicing deep learning in order to aid existing polyp detection machine learning systems through
          <span class="highlighted">data synthesis</span>.</p>
        <p>Generated images are fed into existing machine learning systems to <span class="highlighted"> increase performance</span>.
          Examples of fake generated images may be disturbing, as they are frighteningly realistic.</p>
        <b-button v-if="!showImages" class="button" label="Show Images" @click="toggleImages"></b-button>
        <div class="columns" v-if="showImages">
          <div class="column">
            <img src="../assets/polyps/example-0.png" alt="Generated fake image example">
          </div>
          <div class="column">
            <p>Example of images generated using <span class="highlighted"> Generative Adversarial Networks</span>. The images containing polyps that reflect
            anatomically-plausible abnormalities. The images augment the training set of state-of-the-art polyp detection systems.</p>
            <p>The networks are even able to learn meta-data present in the endoscopy procedure on the left-hand side of the scope.</p>
            <p>Future work into the project will focus on removing abnormalities through hyper-parameter optimization.</p>
            <b-button v-if="showImages" class="button" label="Hide Images" @click="toggleImages"></b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MachineLearningSection",
  data() {
    return {
      showImages: false,
    }
  },
  methods: {
    toggleImages() {
      this.showImages = !this.showImages;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/bulma.scss";

.intro {
  color: $primary;
  font-family: "JetBrains Mono", monospace;
  text-align: left;
  margin-bottom: 1em;
}

.button {
  font-family: "JetBrains Mono", monospace;
}

.content {
  font-family: "JetBrains Mono", monospace;
  text-align: justify;
  margin-left: 7em;
  margin-right: 7em;
}

.highlighted {
  color: $primary;
}

img {
  width: 100%;
  height: auto;
}

</style>